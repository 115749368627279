<template>
    <div class="page-wrapper body-padding-bottom">
        <section class="text-horizontal-scroll" v-if="contentIsLoaded">
            <div class="line line1 even">
                <p class="title" v-for="i in 12" :key="i">{{ content.page.title }}</p>
            </div>
        </section>

        <section class="hero-artiste" data-inview="fadeIn" data-delay="200" v-if="contentIsLoaded">
            <div class="left">
                <div class="overlay"></div>
                <div class="img" :style="'background-image: url('+content.imageSimple.url+');'"></div>
            </div>
            <div class="right">
                <h1 class="title small">{{ content.dateComplete }}</h1>
                <p class="text scene">{{ content.texte }}</p>
                <p class="text description">
                    <span v-html="content.texteSimple"></span>
                </p>
                <div class="social">
                    <a
                        :href="item.lien"
                        target="_blank"
                        v-for="(item, i) in content.liensReseaux"
                        :key="i"
                    >
                        <img :src="item.image.url" :alt="item.image.titre"
                    /></a>
                </div>

                <a v-if="content.lien && content.lienUrl" :href="content.lienUrl" target="_blank" class="button-cta">
                    <p class="title">{{ content.lien.customText }}</p>
                </a>

            </div>
        </section>

        <section class="media full" data-inview="fadeIn" data-delay="200" v-if="contentIsLoaded && content.integrationIframes.qub">
            <div class="qub">
                <span v-html="content.integrationIframes.qub"></span>
            </div>
        </section>

        <section class="media full" data-inview="fadeIn" data-delay="200" v-if="contentIsLoaded && content.integrationIframes.youtube">
            <div class="youtube">
                <span v-html="content.integrationIframes.youtube"></span>
            </div>
        </section>

        <section class="media split" data-inview="fadeIn" data-delay="200" v-if="contentIsLoaded && (content.integrationIframes.autre || content.integrationIframes.autre2)">
            <div class="youtube">
                <span v-html="content.integrationIframes.autre"></span>
            </div>
            <div class="youtube">
                <span v-html="content.integrationIframes.autre2"></span>
            </div>
        </section>

        <section class="lineup others" v-if="pageIsLoaded && otherArtist.length">
            <div class="tab">
                <h2 class="title small intro-title">{{ $t('Autres artistes') }}</h2>

                <router-link
                    :to="artiste.page.jsonUrl"
                    class="artiste-wrap small"
                    data-inview-off="fadeIn"
                    data-delay="200"
                    v-for="artiste in otherArtist"
                    :key="artiste.page.id + 'artiste-loop'"
                >
                    <div class="img">
                        <div class="overlay"></div>
                        <img :src="artiste.imageSimple.url" :alt="artiste.imageSimple.titre" />
                    </div>
                    <footer>
                        <div class="top">
                            <h2 class="title">{{ artiste.page.title }}</h2>
                            <img
                                src="@/assets/img/arrow-right-green.svg"
                                alt="Spotify logo"
                            />
                        </div>
                        <div class="bottom">
                            <p class="text">{{ artiste.heures }}</p>
                            <p class="text">{{ artiste.texte }}</p>
                        </div>
                    </footer>
                </router-link>
            </div>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import { initInViewAnimations } from '@/plugins/InViewAnimations'
import { gsap } from 'gsap'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'Artistes',

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    setTimeout(() => {
                        // Page loaded with Data
                        initInViewAnimations()

                        gsap.to('.line1', { x: '-150vw', duration: 80, ease: 'none', repeat: -1 })
                    }, 155)
                }
            },
            immediate: true,
        },
    },

    data() {
        return {}
    },

    computed: {
        otherArtist() {
            if (!this.pageIsLoaded) {
                return []
            }

            return [...this.teteAffiche6, ...this.programmation6].slice(0, 6)
        },
        teteAffiche6() {
            if (!this.pageIsLoaded) {
                return []
            }

            const artistes = []

            for (const id of this.globals.programmation.teteDaffiche6) {
                const artiste = this.globals.artistes.data.find(i => i.page.id === id)
                if (artiste.page.id !== this.content.page.id) {
                    artistes.push(artiste)
                }
            }

            return artistes
        },
        teteAffiche7() {
            if (!this.pageIsLoaded) {
                return []
            }

            const artistes = []

            for (const id of this.globals.programmation.teteDaffiche7) {
                const artiste = this.globals.artistes.data.find(i => i.page.id === id)
                if (artiste.page.id !== this.content.page.id) {
                    artistes.push(artiste)
                }
            }

            return artistes
        },
        programmation6() {
            if (!this.pageIsLoaded) {
                return []
            }

            const artistes = []

            for (const id of this.globals.programmation.programmation6) {
                const artiste = this.globals.artistes.data.find(i => i.page.id === id)
                if (artiste.page.id !== this.content.page.id) {
                    artistes.push(artiste)
                }
            }

            return artistes
        },
        programmation7() {
            if (!this.pageIsLoaded) {
                return []
            }

            const artistes = []

            for (const id of this.globals.programmation.programmation7) {
                const artiste = this.globals.artistes.data.find(i => i.page.id === id)
                if (artiste.page.id !== this.content.page.id) {
                    artistes.push(artiste)
                }
            }

            return artistes
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<style lang="scss" scoped></style>
